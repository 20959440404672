import { saroAPI } from '../constant'; // No need for image imports now

// Import OpenTok client library
const OT = require('@opentok/client');

// Declare session variable at a higher scope
let session;
let publisher;
let customerCallUUID;
let customerRetailerName;
let isMicrophoneEnabled = true; // Global variable for microphone state
let isVideoEnabled = true; // Global variable for video state
let showInitialImage, showAvailableImage;

// Declare timer variables
let timerNextClerk;
let timer;
let nextClerkTime = 20000;
let callTimeOutTimer = 60000; // Increase the call timeout if needed

let handleVideoToggleRef = null;

// Define setCallStartTime function
function setCallStartTime() {
  // Implementation of setCallStartTime
  // For example, you can log the call start time
  //console.log('Call start time set');
}

function getURLParameter(name) {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(name);
}

const retailerName = getURLParameter('retailerName');

// Set image callbacks for showing images
export function setImageCallbacks(initialImageCallback, availableImageCallback) {
  showInitialImage = initialImageCallback;
  showAvailableImage = availableImageCallback;
}

export function startBroadcast(callUUID, clerkName, retailerName, callType) {
  console.log("Start Brodcast Method Called");
  const apiKey = '9221d0f6-71bd-48a0-976d-5355c89962cf'; // Your API Key
  customerRetailerName = retailerName;
  customerCallUUID = callUUID;

  const token = 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJzY29wZSI6InNlc3Npb24uY29ubmVjdCIsInNlc3Npb25faWQiOiIxX01YNDVNakl4WkRCbU5pMDNNV0prTFRRNFlUQXRPVGMyWkMwMU16VTFZemc1T1RZeVkyWi1makUzTXpBeU9Ea3hPRGczTkRaLVNIVXpXbEp1Y2l0ak1GUkVhbEV2YUhOdWVuZFBhM016Zm41LSIsInJvbGUiOiJwdWJsaXNoZXIiLCJpbml0aWFsX2xheW91dF9jbGFzc19saXN0IjoiZm9jdXMiLCJleHAiOjE3MzU0NzMzMDIsInN1YiI6InZpZGVvIiwiYWNsIjp7InBhdGhzIjp7Ii9zZXNzaW9uLyoqIjp7fX19LCJjb25uZWN0aW9uX2RhdGEiOiJyb2xlPXN1YnNjcmliZXIiLCJqdGkiOiI0YzFmYWVjYS1kM2NkLTQ0MzItOWY3Zi0xODZkNGFmYjllMDAiLCJpYXQiOjE3MzAyODkzMDIsImFwcGxpY2F0aW9uX2lkIjoiOTIyMWQwZjYtNzFiZC00OGEwLTk3NmQtNTM1NWM4OTk2MmNmIn0.Ow5Wq53DBRas5YBreYdoPFtMGVjOpE0B6GgO-Dwzyh9DRUwDoh85KDVoPzOD0dWVVuh6cwQBAcy6ZuE1uZygH1bMjvCfpICc3eFQBLyreAJCK7jGPtj99uyD4QDBmB2sPEqGahm1G9oKOY-9cTUQJ9HzzBFJQdMS4x5b3cJE8VkX2RKsc0K91yOHYfJ96TH9MnCljLQMa8UxkLrcjErWe--NZ_5YL_9n_j9UDmDxDN7fk6gW9T5oOlEb4yRMREgD4eTztI1YGriiNFAhhLvTIwnvYBB-QaeZXrc-OUfGGcP8YRhf-TlyBMId-epaG61uUmbVawfEnvcebJKfJfViiA'; // Replace with actual token
  const sessionId = '1_MX45MjIxZDBmNi03MWJkLTQ4YTAtOTc2ZC01MzU1Yzg5OTYyY2Z-fjE3MzAyODkxODg3NDZ-SHUzWlJucitjMFREalEvaHNuendPa3Mzfn5-'; // Replace with actual session ID
  session = OT.initSession(apiKey, sessionId);

  // Connect to the session
  session.connect(token, error => {
    if (error) {
      handleCallback(error);
    }
  });

  // Subscribe to a newly created stream
  session.on("streamCreated", event => {
    console.log("Stream Connected");
    clearTimeout(timer);
    clearTimeout(timerNextClerk); // Reset call timeout timer
    setCallStartTime(new Date()); // Update call start time when subscriber joins

    // Show the initial image before the stream starts
    // if (showInitialImage) showInitialImage();

    session.subscribe(
      event.stream,
      "subscriber",
      {
        insertMode: "append",
        width: "100%",
        height: "100%",
        style: { buttonDisplayMode: 'off' }
      },
      handleCallback
    );
  });

  session.on('streamDestroyed', event => {
    console.log("Stream Ended");
    if (showAvailableImage) {
      showAvailableImage(); // Call the function to show the image
    }
  });
  

  return session;
}

// End the broadcast session
export function endBroadcast() {
  if (session) {
    session.disconnect();
    session = null; // Clean up session
    console.log('Broadcast session ended.');
  }
}

// End the call
export async function endCall() {
  clearTimeout(timer);
  endSession();
}

// End session function
async function endSession() {
  if (session) {
    session.disconnect();
  } else {
    console.log("Session already disconnected");
  }
}

// Callback handler
function handleCallback(error) {
  if (error) {
    console.error("Error: " + error.message);
  } else {
    console.log("Callback success");
  }
}

// API call to end the call
async function callEndApi() {
  const callUUID = customerCallUUID;
  const retailerName = customerRetailerName;

  try {
    const response = await fetch(`${saroAPI}/endCall`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        "retailerName": retailerName,
        "callUUID": callUUID,
        "callEndedBy": 0
      }),
    });

    if (!response.ok) {
      console.error('API Error:', response.statusText);
    }
  } catch (error) {
    console.error('API Crash Error:', error.message);
  }
}

// Function to toggle video
export function toggleVideo(isVideoOn) {
  if (!isVideoEnabled) {
    return; // Prevent video from being toggled if it's disabled
  }
  publisher.publishVideo(!isVideoOn);
}

// Function to toggle microphone
export function toggleMicrophone(isMicrophoneOn) {
  publisher.publishAudio(!isMicrophoneOn);
}

// Function to set the handleVideoToggle reference
export function setHandleVideoToggleRef(handleVideoToggle) {
  handleVideoToggleRef = handleVideoToggle;
}
