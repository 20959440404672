import React, { useState, useEffect } from 'react';
import './GoLiveCall.css';
import { startBroadcast, endCall, setImageCallbacks } from './GoLiveLanding';
import { useData } from '../../Context.js';
import { useModalContext } from '../../hooks/useModalState';
import livestatus from '../../images/live-black.png';
import zoom from '../../images/Zoom.svg';
import end from '../../images/End.svg';
import { useConfig } from '../../ConfigContext';
import { imageUrl } from '../constant';
import endcallicon from '../../images/endCall.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars,faChevronUp,faChevronDown, faCheckCircle,faTimes, faTimesCircle,faPlusCircle,faMinusCircle, faShoppingCart, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

const Auto = ({ onPageChange }) => {
  const { data } = useData();
  const [sessionId, setSessionId] = useState('');
  const [token, setToken] = useState('');
  const [callStartTime, setCallStartTime] = useState(null);
  const [callDuration, setCallDuration] = useState(0);
  const { setModalState } = useModalContext();
  const [image, setImage] = useState();
  const [isStreamCreated, setIsStreamCreated] = useState(false);
  const [isStreamDestroyed, setIsStreamDestroyed] = useState(false);
  const { configData, textData, imageData, loading: isConfigLoading } = useConfig();
  const [productUrls, setProductUrls] = useState([]);
  const [products, setProducts] = useState([]); // State for fetched products
  const [cart, setCart] = useState([]); // State for the cart
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedVariantId, setSelectedVariantId] = useState('');
  const [isProductSectionVisible, setIsProductSectionVisible] = useState(true);
  const [popupMessage, setPopupMessage] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [popupIcon, setPopupIcon] = useState(null); // Define popupIcon state

  const hardCodedToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiJraGF6YW5heTIiLCJpYXQiOjE3MjM0NjQyODh9.qLkB7xmH1Us_DC1-h1HwvfcxazeElPonoelFSPAXTVU";
  const shopifyAccessToken = "17e1a365f61f5f64d76ba1f4f0988fd7";
  const shopifyStoreUrl = "https://quickstart-ee4009eb.myshopify.com/api/2024-04/graphql.json";

  // State for controlling the center div popup visibility
  const [showCenterDiv, setShowCenterDiv] = useState(false);

  useEffect(() => {
    if (data && data.sessionID) {
      const sessionId = data.sessionID;
      const token = data.token;
      const pakistanZindabad = data.pakistanZindabad

      console.log("Product handler", pakistanZindabad);

      setSessionId(sessionId || '');
      setToken(token || '');

      const sessionInstance = startBroadcast(sessionId, token, onPageChange, showAvailableImage);

      if (sessionInstance) {
        // Listen for stream creation and update the state
        sessionInstance.on('streamCreated', () => {
          setIsStreamCreated(true);
          setIsStreamDestroyed(false); // Reset if the stream is not destroyed
        });

        // Listen for stream destruction and update the state
        sessionInstance.on('streamDestroyed', () => {
          setIsStreamDestroyed(true);
          setIsStreamCreated(false); // Reset created state if stream is destroyed
          showAvailableImage();
        });
      }

      return () => {
        endCall(); // Ensure cleanup
      };
    }
  }, [data]);


  const handleZoomToggle = () => {
    const elem = document.querySelector('.middleDiv');  // Target the white container
    const container = document.querySelector('.CallbodyContainer');  // Get the parent container to add/remove class
    const isInFullscreen = document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement;

    if (!isInFullscreen) {
      // Request fullscreen for the white container (middleDiv)
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen(); // Safari
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen(); // Firefox
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen(); // IE/Edge
      }

    }
  }
  useEffect(() => {
    setModalState('static');
    if (callStartTime) {
      const interval = setInterval(() => {
        const currentTime = new Date();
        const duration = Math.floor((currentTime - callStartTime) / 1000);
        setCallDuration(duration);
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [callStartTime]);

  const fetchProductsByHandles = async (handles) => {
    const query = `
      {
        ${handles.map(handle => `
          ${handle.replace(/-/g, "_")}: productByHandle(handle: "${handle}") {
            id
            title
            description
            images(first: 5) {
              edges {
                node {
                  src
                }
              }
            }
            variants(first: 10) {
              edges {
                node {
                  id
                  title
                  priceV2 {
                    amount
                    currencyCode
                  }
                  image {
                    src
                  }
                }
              }
            }
          }
        `).join("\n")}
      }
    `;

    try {
      const response = await fetch(shopifyStoreUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Shopify-Storefront-Access-Token': shopifyAccessToken,
        },
        body: JSON.stringify({ query }),
      });

      const result = await response.json();

      console.log("Shopify API response:", result);
      if (result.errors) {
        console.error("API Errors:", result.errors);
      }

      if (result.data) {
        const products = Object.values(result.data).filter(Boolean);
        setProducts(products);
      }
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const handleAddToCart = (product) => {
    const selectedVariant = product.variants.edges.find(variant => variant.node.id === selectedVariantId) || product.variants.edges[0];
  
    setCart(prevCart => {
      const existingCartItem = prevCart.find(
        item => item.id === product.id && item.selectedVariant.node.id === selectedVariant.node.id
      );
  
      if (existingCartItem) {
        return prevCart.map(item =>
          item.id === product.id && item.selectedVariant.node.id === selectedVariant.node.id
            ? { ...item, quantity: (item.quantity || 1) + 1 }
            : item
        );
      } else {
        return [...prevCart, { ...product, selectedVariant, quantity: 1 }];
      }
    });
  
setPopupMessage(`${textData?.P2BTP1}`);

    setPopupIcon(faCheckCircle);
    setShowPopup(true);
  
    setTimeout(() => setShowPopup(false), 3000);
  };
  
  const handleRemoveFromCart = (product) => {
    const selectedVariant = product.variants.edges.find(variant => variant.node.id === selectedVariantId) || product.variants.edges[0];
  
    setCart(prevCart => {
      const existingCartItem = prevCart.find(
        item => item.id === product.id && item.selectedVariant.node.id === selectedVariant.node.id
      );
  
      if (existingCartItem && existingCartItem.quantity > 1) {
        return prevCart.map(item =>
          item.id === product.id && item.selectedVariant.node.id === selectedVariant.node.id
            ? { ...item, quantity: item.quantity - 1 }
            : item
        );
      } else {
        return prevCart.filter(
          item => !(item.id === product.id && item.selectedVariant.node.id === selectedVariant.node.id)
        );
      }
    });
  
    setPopupMessage(`${textData?.P2BTP2}`);
    setPopupIcon(faTimesCircle);
    setShowPopup(true);
  
    setTimeout(() => setShowPopup(false), 3000);
  };
  
  

  const isInCart = (product) => {
    return cart.some(
      (cartItem) =>
        cartItem.id === product.id &&
        cartItem.selectedVariant.node.id ===
          (product.variants.edges.find((variant) => variant.node.id === selectedVariantId) || product.variants.edges[0]).node.id
    );
  };
  

  const handleCheckout = async () => {
    if (cart.length === 0) {
      alert("Your cart is empty. Add products to cart before checking out.");
      return;
    }
  
    // Prepare cart items with the correct quantity
    const cartItems = cart.map(item => ({
      variantId: item.selectedVariant.node.id, // Use the correct variant ID for each item
      quantity: item.quantity, // Use the actual quantity from the cart
    }));
  
    const checkoutMutation = `
      mutation {
        checkoutCreate(input: {
          lineItems: ${JSON.stringify(cartItems).replace(/"([^"]+)":/g, '$1:')}
        }) {
          checkout {
            webUrl
          }
          checkoutUserErrors {
            message
          }
        }
      }
    `;
  
    try {
      const response = await fetch(shopifyStoreUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Shopify-Storefront-Access-Token': shopifyAccessToken,
        },
        body: JSON.stringify({ query: checkoutMutation }),
      });
  
      const result = await response.json();
      const checkoutUrl = result.data?.checkoutCreate?.checkout?.webUrl;
      if (checkoutUrl) {
        window.location.href = checkoutUrl;
      } else {
        alert("Checkout error: " + result.data.checkoutCreate.checkoutUserErrors[0]?.message);
      }
    } catch (error) {
      console.error("Error during checkout:", error);
      alert("There was an error initiating checkout. Please try again.");
    }
  };
  
  useEffect(() => {
    if (data?.pakistanZindabad && data.pakistanZindabad.length > 0) {
      // Using dynamically extracted product handles
      console.log("Fetched Product Handles:", data.pakistanZindabad);
      fetchProductsByHandles(data.pakistanZindabad);
    } else {
      console.warn("No product handles found in data.");
    }
  }, [data?.pakistanZindabad]);


  const formatDuration = () => {
    const hours = Math.floor(callDuration / 3600);
    const minutes = Math.floor((callDuration % 3600) / 60);
    const seconds = callDuration % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  const showAvailableImage = () => {
    const availableImage = window.innerWidth <= 768 ? imageData?.P1MobileImage : imageData?.P1image;
    setImage(availableImage);
  };

  const handleProductSelection = (product) => {
    setSelectedProduct(product);
    setSelectedVariantId(product.variants.edges[0]?.node.id || '');
  };

  const handleVariantChange = (variantId) => {
    const variant = selectedProduct.variants.edges.find((variant) => variant.node.id === variantId);
    if (variant) {
      setSelectedVariantId(variantId);
      // Update selected product title, price, and image based on selected variant
      setSelectedProduct((prevProduct) => ({
        ...prevProduct,
        title: variant.node.title,  // Update to show only the selected variant's title
        images: { edges: [{ node: { src: variant.node.image?.src || prevProduct.images.edges[0]?.node.src } }] },
        price: variant.node.priceV2.amount,
        currencyCode: variant.node.priceV2.currencyCode,
      }));
    }
  };

  const handleProductClick = (product) => {
    setSelectedProduct(product);
    setSelectedVariantId(product.variants.edges[0]?.node.id || '');
  };

  const handleToggleProductSection = () => {
    setIsProductSectionVisible(!isProductSectionVisible);
  
    const container = document.querySelector('.goliveCall-container');
    const productSection = document.querySelector('.product-section');
    const toggleButton = document.querySelector('.product-toggle');
  
    // Check if elements exist before modifying classes
    if (container && productSection && toggleButton) {
      if (!isProductSectionVisible) {
        // Expanding video to full width
        container.classList.add("full-width");
        productSection.classList.add("hidden");
        toggleButton.classList.add("full-width");
      } else {
        // Restoring to original layout
        container.classList.remove("full-width");
        productSection.classList.remove("hidden");
        toggleButton.classList.remove("full-width");
      }
    } else {
      console.error("One or more elements could not be found in the DOM.");
    }
  };
  
  
  
  return (
    <div className="goliveCall-container">
      {/* Product Section */}
      {isStreamCreated && isProductSectionVisible && (
        <div className={`product-section ${window.innerWidth <= 768 ? 'product-section-mobile' : ''}`}>
          <div className="d-flex justify-content-between align-items-center">
            <h2 style={{ marginLeft: '10px' }}>{textData?.P2BT1}</h2>
            {cart.length > 0 && (
              <button className="checkout-btn" onClick={handleCheckout}>
                {textData?.P2BTBtn1}
                <FontAwesomeIcon icon={faShoppingCart} style={{ marginLeft: '5px' }} />
              </button>
            )}
          </div>
          <div className="product-list">
            {selectedProduct ? (
              <div className="product-details">
                <div className="close-icon" onClick={() => setSelectedProduct(null)}>
                  <FontAwesomeIcon icon={faTimes} size="lg" style={{ cursor: 'pointer' }} />
                </div>
                <img
                  src={selectedProduct.images.edges[0]?.node.src}
                  alt={selectedProduct.title}
                  className="product-image-large"
                />
                <p className="product-title">{selectedProduct.title}</p>
                <p className="product-price">
                  {selectedProduct.variants.edges.find(variant => variant.node.id === selectedVariantId)?.node.priceV2.amount}{" "}
                  {selectedProduct.variants.edges.find(variant => variant.node.id === selectedVariantId)?.node.priceV2.currencyCode}
                </p>
                {selectedProduct.variants.edges.length > 1 && (
                  <select
                    className="variant-select"
                    value={selectedVariantId}
                    onChange={(e) => handleVariantChange(e.target.value)}
                  >
                    {selectedProduct.variants.edges.map(variant => (
                      <option key={variant.node.id} value={variant.node.id}>
                        {variant.node.title} - {variant.node.priceV2.amount} {variant.node.priceV2.currencyCode}
                      </option>
                    ))}
                  </select>
                )}
                <button onClick={() => handleAddToCart(selectedProduct)} className="add-to-cart-btn">
                  {textData?.P2BTBtn2}
                </button>
              </div>
            ) : (
              products.map((product, index) => (
                <React.Fragment key={product.id}>
                  <div className="product-item d-flex align-items-center">
                    <img
                      src={product.images.edges[0]?.node.src}
                      alt={product.title}
                      className="product-image"
                      onClick={() => handleProductClick(product)}
                      style={{ cursor: 'pointer' }}
                    />
                    <div className="product-info">
                      <p className="product-title" onClick={() => handleProductClick(product)} style={{ cursor: 'pointer' }}>
                        {product.title}
                      </p>
                      <p className="product-price" onClick={() => handleProductClick(product)} style={{ cursor: 'pointer' }}>
                        {product.variants.edges[0]?.node.priceV2.amount}{" "}
                        {product.variants.edges[0]?.node.priceV2.currencyCode}
                      </p>
                    </div>
                    <div className="add-to-cart-icon">
                      {isInCart(product) && (
                        <div className="quantity-control">
                          <FontAwesomeIcon icon={faMinusCircle} size="lg" onClick={() => handleRemoveFromCart(product)} style={{ marginRight: '8px' }} />
                          <span className="quantity-number">
                            {cart.find(cartItem => cartItem.id === product.id)?.quantity || 1}
                          </span>
                        </div>
                      )}
                      <FontAwesomeIcon icon={faPlusCircle} size="lg" onClick={() => handleAddToCart(product)} style={{ marginLeft: '8px' }} />
                    </div>
                  </div>
                </React.Fragment>
              ))
            )}
          </div>
        </div>
      )}

      {/* Web Product Toggle Button (Chevron Left/Right) */}
      {isStreamCreated && window.innerWidth > 768 && (
        <div
          className="product-toggle"
          onClick={handleToggleProductSection}
          style={{ right: isProductSectionVisible ? '500px' : '860px', left: isProductSectionVisible ? 'auto' : 'auto' }}
        >
<FontAwesomeIcon icon={ faBars } />
</div>
      )}

      {/* Mobile Product Toggle Button */}
      {window.innerWidth <= 768 && (
        <button className="mobile-product-toggle" onClick={handleToggleProductSection}>
          <FontAwesomeIcon icon={isProductSectionVisible ? faChevronDown : faChevronUp} />
     
        </button>
      )}

      {/* Video Section */}
      <div
        className="goLiveCall-video-section"
        style={{
          width: isStreamCreated && isProductSectionVisible && window.innerWidth > 768 ? '60%' : '100%',
        }}
      >
        <div className="overlayy d-flex flex-column justify-content-between">
          <div className="d-flex justify-content-between livebtn z_index">
            <div className="liveLogoDiv">
              <img src={livestatus} className="livestatusimg" width="60px"  alt="Live Status" />
            </div>
            <div className="callDurationDiv">
              <p>{formatDuration()}</p>
            </div>
          </div>

          <div className="video-call" id="subscriber">
            {isStreamDestroyed ? (
              <img
                src={window.innerWidth <= 768 ? imageData?.B2Mimage : imageData?.B2image}
                alt="Stream Ended"
                className="call-image"
              />
            ) : !isStreamCreated ? (
              <img
                src={window.innerWidth <= 768 ? imageData?.B1Mimage : imageData?.B1image}
                alt="Call State"
                className="call-image"
              />
            ) : (
              <div className="call-screen">
                {/* Streaming component will be displayed here */}
              </div>
            )}
          </div>

          {/* Zoom and End Call Controls */}
          <div
            className="videoNavbar"
            style={{
              position: 'absolute',
              bottom: '10px',
              left: '30%',
              display: 'flex',
              gap: '50px',
            }}
          >
            <div className="button-container" style={{ display: 'flex', gap: '100px' }}>
              <div onClick={handleZoomToggle}>
                <img
                  src={`${imageUrl}${zoom}`}
                  alt="Zoom"
                  style={{ width: '100px', height: '45px' }}
                />
              </div>
              <div onClick={() => setShowCenterDiv(true)}>
                <img
                  src={`${imageUrl}${end}`}
                  alt="End Call"
                  style={{ width: '100px', height: '45px' }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Center Div for End Call Confirmation */}
      {showCenterDiv && (
        <div
          id="centerDiv"
          className="centerDiv d-flex flex-column justify-content-evenly"
          style={{
            position: 'fixed',
            top: '45%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '240px',
            height: '230px',
            backgroundColor: '#fff',
            border: '2px solid black',
            borderRadius: '20px',
            padding: '20px 0',
          }}
        >
          <div className="headicon d-flex justify-content-center">
            <img src={endcallicon} alt="End Call Icon" className="imgicon5" />
          </div>
          <h3 style={{ fontSize: '18px', marginBottom: '15px', textAlign: 'center' }}>
            {textData?.P4P1}
          </h3>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '8px' }}>
            <button
              onClick={() => {
                endCall();
                onPageChange("BroadcastFeedback");
              }}
              style={{
                width: '100px',
                height: '30px',
                fontSize: '16px',
                fontWeight: 'bold',
                backgroundColor: 'black',
                color: 'white',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
              }}
            >
              {textData?.P4PBtn1}
            </button>
            <button
              onClick={() => setShowCenterDiv(false)}
              style={{
                width: '100px',
                height: '30px',
                fontSize: '16px',
                fontWeight: 'bold',
                backgroundColor: 'white',
                color: 'black',
                border: '1px solid black',
                borderRadius: '5px',
                cursor: 'pointer',
              }}
            >
              {textData?.P4PBtn2}
            </button>
          </div>
        </div>
      )}
    </div>
  );
    
};

export default Auto;